import './styles/main.scss';
import ReactDOM from 'react-dom/client';
import { StrictMode } from 'react';
import { App } from './app';


const rootElement: HTMLElement | null = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement as HTMLElement);

root.render(
    <StrictMode>
        <App />
    </StrictMode>
);
